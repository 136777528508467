@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: hsl(0, 0%, 97%)


  }

  @keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.floating-testimonial {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
}

.testimonial-item {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animated-section {
  animation: fadeIn 0.5s ease-in-out; /* Ubah sesuai kebutuhan durasi dan jenis animasi */
}

  .video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}
 
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@keyframes text-run {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

/* Gaya untuk item slide pada slider */
.slider-item {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Efek transisi saat slide berubah */
  border-radius: 12px; /* Sudut melengkung pada item */
  overflow: hidden; /* Memastikan konten di dalam item tidak terpotong */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Shadow default untuk item */
}

/* Gaya untuk item slide saat dihover */
.slider-item:hover {
  transform: translateY(-5px); /* Menggeser item ke atas saat dihover */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Shadow saat dihover */
}


/* Gaya untuk teks pada item saat ini */
.slider-item.active .text-sm {
  color: #A020F0; /* Warna teks untuk item saat ini */

}


.animate-text-run {
  display: inline-block;
  overflow: hidden;
  animation: text-run 5s linear infinite; /* Sesuaikan durasi dan timing animation di sini */
}

.rectangle:hover {
  transform: scale(1.05);
}
button.locked {
  opacity: 0.5;
  cursor: not-allowed;
}
.spotlight {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  background-color: red; /* Change the background color to red */
}


@media (max-width: 768px) {
  /* properti CSS untuk layar dengan lebar maksimum 768px */
  .saldo {
    font-size: 16px;
  }
}

@media (min-width: 769px) {
  /* properti CSS untuk layar dengan lebar minimum 769px */
  .saldo {
    font-size: 24px;
  }
}




@media (max-width: 640px) {
  .rectangle {
    grid-column: span 2;
  }
}
.rectangle:hover .fa-icon {
  transform: rotate(45deg);
}

/* CSS */

.frame53 {
  position: absolute;
  background-color: #571515;
  height: 100vh;
  width: 100vw;
  padding: 0;
}

.rectangle15571 {
  background-color: #d9d9d9;
  height: 271px;
  width: 325px;
  left: calc(50% - 162.5px); /* Center the element horizontally */
  top: 389px;
  position: absolute;
}

.yourname {
  color: #ffffff;
  text-align: left;
  vertical-align: text-top;
  font-size: 6vw; /* Responsive font size */
  font-family: Inter;
  left: calc(50% - 189.5px); /* Center the element horizontally */
  top: calc(50% + 92px); /* Position the element vertically */
  width: 379px;
  height: 97px;
  position: absolute;
  line-height: auto;
  border-style: hidden;
  outline: none;
}

.yourname.submitted {
  font-size: 80px; /* Font size for the submitted name */
}

.frame67 {
  position: absolute;
  background-color: #ffffff;
  height: 720px;
  width: 1280px;
  padding: 0px;
}

.rectangle15668 {
  background-color: #d9d9d9;
  height: 20px;
  width: 54px;
  left: 5px;
  top: 25px;
  position: absolute;
}

.hover\:gradient-text {
  color: white; /* Set text color to white */
  transition: color 0.3s ease;
}

.hover\:gradient-text:hover {
  color: gray; /* Change text color to cyan on hover */
}




@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fall-animation {
  animation: fall-animation 1s ease-in-out;
}

@keyframes fall-animation {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}




.inactiveStatus {
  background-color: red;
  color: white;
border-radius: 3px;
padding: 0.5em;
}


.pagination-link.active {
  background-color: #6767db; /* Replace with your desired color */
}

.rectangle15669 {
  background-color: #d9d9d9;
  height: 20px;
  width: 54px;
  top: 25px;
  left: 5px;
  position: absolute;
}

.nama {
  color: #000000;
  text-align: center;
  vertical-align: middle;
  font-size: 17px;
  font-family: Arial;
  left: 6px;
  top: 25px;
  width: 48px;
  height: 23px;
  position: absolute;
  letter-spacing: 0.2280000001192093px;
  line-height: 23px;
  border-style: hidden;
  outline: none;
}

.rectangle15670 {
  background-color: #d9d9d9;
  height: 20px;
  width: 54px;
  top: 55px;
  left: 5px;
  position: absolute;
}

.email {
  color: #000000;
  text-align: center;
  vertical-align: middle;
  font-size: 17px;
  font-family: Arial;
  left: 7px;
  top: 55px;
  width: 47px;
  height: 23px;
  position: absolute;
  letter-spacing: 0.2280000001192093px;
  line-height: 23px;
  border-style: hidden;
  outline: none;
}

.rectangle15671 {
  background-color: #d9d9d9;
  height: 20px;
  width: 54px;
  top: 88px;
  left: 6px;
  position: absolute;
}

.telepon {
  color: #000000;
  text-align: center;
  vertical-align: middle;
  font-size: 17px;
  font-family: Arial;
  top: 88px;
  width: 66px;
  height: 23px;
  position: absolute;
  letter-spacing: 0.2280000001192093px;
  line-height: 23px;
  border-style: hidden;
  outline: none;
}



.rectangle15672 {
  background-color: #d9d9d9;
  height: 20px;
  width: 54px;
  top: 25px;
  left: 96px;
  position: absolute;
}

/* Tambahkan gaya untuk tabel */
table {
  width: 100%;
  border-collapse: collapse;
}

/* Gaya untuk header kolom */
th {
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

/* Gaya untuk sel data */
td {
  border: 1px solid #ddd;
  padding: 8px;
}

/* Gaya untuk baris ganjil */
tr:nth-child(odd) {
  background-color: #f9f9f9;
}

/* Gaya untuk baris genap */
tr:nth-child(even) {
  background-color: #ffffff;
}

/* Gaya untuk progress bar */
progress {
  width: 100%;
}





/* Gaya untuk tombol */
.button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}


.alamat {
  color: #000000;
  text-align: center;
  vertical-align: middle;
  font-size: 17px;
  font-family: Arial;
  left: 92px;
  top: 25px;
  width: 58px;
  height: 23px;
  position: absolute;
  letter-spacing: 0.2280000001192093px;
  line-height: 23px;
  border-style: hidden;
  outline: none;
}

.rectangle15673 {
  background-color: #ff0000;
  height: 20px;
  width: 54px;
  top: 58px;
  left: 96px;
  position: absolute;
}

.generate {
  color: #000000;
  text-align: center;
  vertical-align: middle;
  font-size: 17px;
  font-family: Arial;
  left: 85px;
  top: 56px;
  width: 76px;
  height: 23px;
  position: absolute;
  letter-spacing: 0.2280000001192093px;
  line-height: 23px;
  border-style: hidden;
  outline: none;
}
